import React from "react";
import RegisterIcon from "./../../images/register.svg";
import CheckIcon from "./../../images/check.svg";
import WalletIcon from "./../../images/wallet.svg";
import TradeIcon from "./../../images/trade.svg";
import GetStartedItem from "./get-started-item";
import "./style.css";
import BlueButton from "../blue-button";

const GetStarted = () => {
  return (
    <div className="container my-20 sm:my-40">
      <div className="relative grid gap-y-20 justify-center">
        <h3 className="text-2xl sm:text-4xl font-bold text-center text-gray-900">
          Get started in minutes
        </h3>
        <div className="get-started-items grid grid-cols-1 sm:grid-cols-4 gap-x-4">
          <GetStartedItem
            step="01"
            icon={RegisterIcon}
            title="Register"
            text="Create you trade account on bnax in a few simple click"
          />
          <GetStartedItem
            step="02"
            icon={CheckIcon}
            title="Verify"
            text="Verify your identify with one of our trusted verification parthers"
          />
          <GetStartedItem
            step="03"
            icon={WalletIcon}
            title="Deposit"
            text="Securely connect your bank account to deposit founds"
          />
          <GetStartedItem
            step="04"
            icon={TradeIcon}
            title="Trade"
            text="Buy, sell and swap digital assets 24/7"
          />
        </div>
        <div className="flex justify-center">
          <BlueButton anchor="/signup" text="Start earning"></BlueButton>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
