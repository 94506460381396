import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { coins } from "../data/coins";
import { getKLines, getTicker24h } from "../utils/binance/api";
import PlotlyLoadable from "../utils/plotly-loadable";
import { getKByToken } from "../utils/tools";

type PlotBoxProps = {
  _1stCoin: string;
  _2ndCoin: string;
};

const PlotBox = (props: PlotBoxProps) => {
  const f = new Intl.NumberFormat("en-US");

  const [x, setX] = useState<number[]>([]);
  const [y, setY] = useState<number[]>([]);
  const [lastPrice, setLastPrice] = useState<number>(0);
  const [performance, setPerformance] = useState<number>(0);
  const [performancePercent, setPerformancePercent] = useState<number>(0);

  useEffect(() => {
    getTicker24h(
      `${props._1stCoin}${props._2ndCoin}`,
      (
        _lastPrice,
        _priceChange24h,
        _priceChangePercent24h,
        _highPrice24h,
        _lowPrice24h,
        _volume,
        _quoteVolume,
        _weightedAvgPrice,
        _bidPrice,
        _askPrice,
        _prevClosePrice
      ) => {
        setLastPrice(_lastPrice);
      }
    );

    const symbol = `${props._1stCoin}${props._2ndCoin}`;
    getKLines(symbol, "1h", 720, function (date, _open, _high, _low, close, _volume) {
      setX(date);
      setY(close);

      const onePercent = close[0] / 100;
      setPerformance(close[close.length - 1] - close[0]);
      setPerformancePercent((close[close.length - 1] - close[0]) / onePercent);
    });
  }, []);

  return (
    <Link
      to={`/markets/${props._1stCoin}_${props._2ndCoin}`}
      className="relative h-60 px-4 py-2 bg-white rounded drop-shadow-md sm:drop-shadow-xl sm:hover:drop-shadow-lg sm:hover:scale-105 transition duration-500 ease-in-out cursor-pointer"
    >
      <div className="flex justify-between">
        <div>
          <p className="text-base text-gray-900 font-bold">
            {props._1stCoin + " / " + props._2ndCoin}
          </p>
          <p className="text-xs text-gray-400 font-normal">Last 30 days</p>
        </div>
        <div className="text-base font-medium">
          <p>
            {"$" + f.format(lastPrice)}
            <span className={performancePercent > 0 ? "text-green-600" : "text-red-500"}>
              {" (" + Number(performancePercent).toFixed(2) + "%)"}
            </span>
          </p>
        </div>
      </div>
      <PlotlyLoadable
        className="w-full h-5/6"
        useResizeHandler={true}
        data={[
          {
            x: x,
            y: y,
            type: "scatter",
            mode: "lines",
            marker: { color: "blue" },
          },
        ]}
        layout={{
          autosize: true,
          xaxis: {
            visible: false,
          },
          yaxis: {
            visible: false,
            showgrid: false,
            zeroline: false,
          },
          title: "",
          showlegend: false,
          margin: {
            l: 20,
            r: 20,
            b: 20,
            t: 20,
            pad: 4,
          },
        }}
        config={{ staticPlot: true, doubleClick: false, responsive: true, autosizable: true }}
      />
    </Link>
  );
};

export default PlotBox;
