import React, { useEffect, useState } from "react";
import { getTicker24h } from "../../utils/binance/api";
import { getCoinLogoBySymbol, prettifyNumber } from "../../utils/tools";

type TableRowProps = {
  _1stCoin: string;
  _2ndCoin: string;
};

const TableRow = (props: TableRowProps) => {
  const f = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 6 });
  const thClass = "align-middle text-sm px-4 sm:px-6 py-5 font-medium";

  const [lastPrice, setLastPrice] = useState<number>(-1);
  const [priceChangePercent, setPriceChangePercent] = useState<number>(0);
  const [volume, setVolume] = useState<number>(-1);
  const [lowPrice, setLowPrice] = useState<number>(-1);
  const [highPrice, setHighPrice] = useState<number>(-1);

  useEffect(() => {
    getTicker24h(
      `${props._1stCoin}${props._2ndCoin}`,
      (
        _lastPrice,
        _priceChange24h,
        _priceChangePercent24h,
        _highPrice24h,
        _lowPrice24h,
        _volume,
        _quoteVolume,
        _weightedAvgPrice,
        _bidPrice,
        _askPrice,
        _prevClosePrice
      ) => {
        setLastPrice(_lastPrice);
        setPriceChangePercent(_priceChangePercent24h);
        setVolume(_quoteVolume);
        setLowPrice(_lowPrice24h);
        setHighPrice(_highPrice24h);
      }
    );
  }, []);

  return (
    <tr className="border-b">
      <th className={thClass}>
        <div className="flex flex-row items-center relative">
          <div className="w-12 lg:w-8 w-12 lg:h-8 mr-4">
            <img
              src={getCoinLogoBySymbol(props._1stCoin)}
              className="w-full h-full align-top rounded-full overflow-hidden"
            ></img>
          </div>
          <div className="absolute left-4 -bottom-2 flex w-6 h-6 border-2 border-white rounded-full overflow-hidden">
            <img
              src={getCoinLogoBySymbol(props._2ndCoin)}
              className="w-full h-full align-top"
            ></img>
          </div>
          <span className="uppercase font-medium text-xs sm:text-sm">
            {props._1stCoin + " / " + props._2ndCoin}
          </span>
        </div>
      </th>
      <th className={thClass}>
        <div></div>
        <span>{lastPrice > 0 ? "$" + f.format(lastPrice) : "-"}</span>
      </th>
      <th className={thClass}>
        <span className={priceChangePercent > 0 ? "text-green-600" : "text-red-500"}>
          {priceChangePercent + "%"}
        </span>
      </th>
      <th className={thClass}>
        <span>{volume > 0 ? "$" + prettifyNumber(volume) : "-"}</span>
      </th>
      <th className={thClass}>
        <span>{lowPrice > 0 ? f.format(lowPrice) : "-"}</span>
      </th>
      <th className={thClass}>
        <span>{highPrice > 0 ? f.format(highPrice) : "-"}</span>
      </th>
    </tr>
  );
};

export default TableRow;
