import React from "react";
import "./style.css";

type GetStartedItemProps = {
  step: string;
  icon: any;
  title: string;
  text: string;
};

const GetStartedItem = (props: GetStartedItemProps) => {
  return (
    <div className="get-started-item">
      <div data-step={props.step} className="get-started-icon">
        <div className="relative z-10">
          <img src={props.icon}></img>
        </div>
      </div>
      <span className="get-started-label text-xl text-gray-900 font-bold">{props.title}</span>
      <p className="get-started-note text-md text-gray-500">{props.text}</p>
    </div>
  );
};

export default GetStartedItem;
